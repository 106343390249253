<template>
  <div class="border-bottom">
    <div class="custom-card-title">
      {{ title }}
    </div>
  </div>
</template>
<script>

export default {
  name: "custom-card-title",

  props: {
    title: {
      type: String,
      required: true
    },
  },

  mounted() {
    this.$root.$on("change-language");
  },

  beforeDestroy() {
    this.$root.$off("change-language");
  }

}
</script>